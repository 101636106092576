/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Helmet } from "react-helmet";
import Page from "./Page";

export default class IndexPage extends React.Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: "https://github.com/facebook/react/issues/10923"
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Page />
        <Helmet>
          <html />
          <body />
          <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
          <meta charSet="utf-8" />
          <title>Error 502</title>
          <style cssText='html{font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;font-weight:300;background-color:#eee;}h1{font-weight:100;}body{margin:3em;}img{display:block;width:100px;height:100px;margin:0 auto;}div{max-width:20em;margin:0 auto;text-align:center;}' />
        </Helmet>
        ;
      </React.Fragment>
    );
  }
}
