import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div>
          <img alt />
          <h1>502 Bad Gateway</h1>
          <p />
        </div>
      </div>
    );
  }
}

export default Page;
